<template>
  <div id='artcleList'>
    <!-- 标题 -->
    <div class="labelBox">
      <div class="title">文章管理</div>
      <div>
        <el-button type="primary" size="medium" @click="add">添加文章</el-button>
      </div>
    </div>
    <div style="margin:10px 0">
      <el-radio-group v-model="showType" @change="typeChange">
        <el-radio :label="1">我发表的文字</el-radio>
        <el-radio :label="0">全部文章</el-radio>
      </el-radio-group>
    </div>

    <!-- 文章列表 -->
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="id" label="ID">
      </el-table-column>
      <el-table-column prop="title" label="标题">
      </el-table-column>
      <el-table-column label="列表图片">
        <template slot-scope="scope">
          <el-image style="width: 100%; height: 100px" :src="scope.row.img" fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="推荐用户">
        <template slot-scope="scope">
          <p style="margin-left: 10px">性别：{{ scope.row.sex }}</p>
          <p style="margin-left: 10px">年龄：{{ scope.row.age }}</p>
        </template>
      </el-table-column>
      <el-table-column label="所属小程序">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.minipro" size="small" :key="item" style="margin:0 5px 5px 0">{{item}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否展示">
        <template slot-scope="scope">
          <el-tag type="success" size="small" v-if="scope.row.isShow">展示</el-tag>
          <el-tag type="danger" size="small" v-else>隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="aname" label="作者">
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical" v-if="scope.row.aid == aid"></el-divider>
          <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)" v-if="scope.row.aid == aid">
            <el-button type="text" slot="reference" style="color:#F56C6C">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginationBox">
      <el-pagination :page-size="page.pageSize" :total="page.total" :current-page="page.currentPage"
        layout="prev, pager, next" @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <!-- 添加文章弹窗 -->
    <el-dialog :title="editTitle" :visible.sync="editModal" width="70%" top="1vh">
      <div class="modalBox">
        <el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px" class="demo-ruleForm">
          <el-form-item label="文章标题" prop="title" required>
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="列表图片" prop="img" required>
            <el-upload class="avatar-uploader" action="http://upload.qiniup.com" :show-file-list="false" :data="qn"
              :on-success="handleAvatarSuccess" :before-upload="beforeUpload">
              <img v-if="editForm.img" :src="editForm.img" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon" style="background: #fafafa;"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="推荐用户性别">
            <el-radio-group v-model="editForm.sex">
              <el-radio label="全部"></el-radio>
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="推荐用户年龄">
            <el-radio-group v-model="editForm.age">
              <el-radio label="全部"></el-radio>
              <el-radio label="0-20"></el-radio>
              <el-radio label="21-30"></el-radio>
              <el-radio label="31-40"></el-radio>
              <el-radio label="41-50"></el-radio>
              <el-radio label="50以上"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属小程序">
            <el-checkbox-group v-model="editForm.minipro">
              <el-checkbox :label="item.id" v-for="(item,index) in miniList" :key="index">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="是否展示" prop="isShow">
            <el-switch v-model="editForm.isShow" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="文章详情" prop="content">
            <tinymce ref="content" v-model="editForm.content" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editModal = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :disabled="editForm.aid != aid && editTitle == '编辑文章'">保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import tinymce from '@/components/tinymce.vue'
  var storage = window.localStorage;

  const EditFormData = {
    title: '',
    img: '',
    sex: '全部',
    age: '全部',
    isShow: 1,
    minipro: [],
  }

  export default {
    name: 'artcleList',
    components: {
      tinymce
    },
    data() {
      return {
        showType: 1,
        qn: {
          token: "",
          key: ""
        },
        qnUrl: 'http://healthqnytp.ifufan.com/',
        aid: '',
        id: '',
        miniList: [],
        list: [],
        page: {
          currentPage: 1,
          pageSize: 10,
          total: ''
        },
        editModal: false,
        editTitle: '',
        editForm: EditFormData,
        rules: {
          title: [{
            required: true,
            message: '请填写文章标题',
            trigger: 'blur'
          }, ],
          img: [{
            required: true,
            message: '请上传列表图片',
            trigger: 'change'
          }],
        }
      }
    },

    created() {
      this.aid = storage.aid
      this.getList()
      this.getMiniList()
      this.getQnToken()
    },

    methods: {
      // 获取上传七牛云Token
      getQnToken() {
        this.$http.post('/qiniu/token').then(res => {
          if (res.data.code == 1) {
            this.qn.token = res.data.data
          }
        })
      },

      // 上传前验证文件
      beforeUpload(file) {
        this.qn.key = `${new Date().getTime()}_${file.name}`
        // debugger
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt10M = file.size / 1024 / 1024 < 10;

        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG/PNG 格式!");
        }
        if (!isLt10M) {
          this.$message.error("上传图片大小不能超过 10MB!");
        }
        return isJPG && isLt10M;
      },

      // 上传成功回调
      handleAvatarSuccess(res) {
        this.editForm.img = this.qnUrl + res.key
      },

      add() {
        this.editForm = ''
        this.editForm = EditFormData
        this.id = ''
        this.editTitle = '添加文章'
        this.editModal = true
      },

      edit(id) {
        this.id = id
        this.editTitle = '编辑文章'
        this.$http.post('/article/view', {
          id: this.id
        }).then(res => {
          if (res.data.code == 1) {
            this.editForm = res.data.data
            this.editModal = true
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      submitForm() {
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            var data = {
              title: this.editForm.title,
              aid: storage.aid,
              type: storage.type,
              img: this.editForm.img,
              sex: this.editForm.sex,
              age: this.editForm.age,
              minipro: JSON.stringify(this.editForm.minipro),
              isShow: this.editForm.isShow,
              content: this.editForm.content,
            }
            if (this.id) {
              data.id = this.id
            }
            this.$http.post('/article/edit', data).then(res => {
              if (res.data.code == 1) {
                this.editModal = false
                this.getList()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      del(id) {
        this.$http.post('/article/del', {
          id: id
        }).then(res => {
          if (res.data.code == 1) {
            this.getList()
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      typeChange(e) {
        console.log(e)
        this.showType = e
        this.getList()
      },

      handleCurrentChange(val) {
        this.page.currentPage = val;
        this.getList()
      },

      getList() {
        this.$http.post('/article/list', {
          showType:this.showType,
          aid:this.aid,
          page: this.page.currentPage,
          pageSize: this.page.pageSize
        }).then(res => {
          if (res.data.code == 1) {
            this.list = res.data.data
            this.page.total = res.data.pageTotal
          }
        })
      },

      getMiniList() {
        this.$http.post('/miniConfig/list', {}).then(res => {
          if (res.data.code == 1) {
            this.miniList = res.data.data
          }
        })
      }
    }
  }
</script>

<style scoped>
  #artcleList {
    padding: 30px;
    background: #fff;
  }

  .modalBox {
    padding-right: 100px;
    height: 80vh;
    /* overflow:scroll; */
    overflow-y: auto
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: auto;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: auto;
    display: block;
  }

  .paginationBox {
    margin: 20px 0;
    text-align: right;
  }
</style>